// Cerebro/ClientApp/src/pages/Dashboard/EHR/Dialog/AddPatient.js

import React, { useState } from 'react';
import './AddPatient.css';
import backIcon from '../../../../assets/icons/Close.png';
import useFetchWithAuth from '../../../../utils/useFetchWithAuth';
import { useMessage } from '../../../../components/Message/MessageProvider';
import InputMask from 'react-input-mask';

const AddPatient = ({ onSave, onClose }) => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [activeTab, setActiveTab] = useState('demographics');
    const [errors, setErrors] = useState({});
    const [patientData, setPatientData] = useState({
        FirstName: '',
        MiddleName: '',
        LastName: '',
        PreferredName: '',
        DateOfBirth: null,
        SexAssignedAtBirth: '',
        GenderIdentity: '',
        PreferredPronouns: '',
        PhoneNumber: '',
        EmailAddress: '',
        Street: '',
        City: '',
        State: '',
        ZipCode: '',
        Country: '',
        EmergencyContactName: '',
        EmergencyContactRelationship: '',
        EmergencyContactPhone: '',
        LegalAuthRepName: '',
        LegalAuthRepRelationship: '',
        LegalAuthRepPhone: '',
        Insurance: {
            Carrier: '',
            Subscriber: '',
            Coverage: '',
            SubscriberId: '',
            Group: '',
            RxBIN: ''
        }
    });

    // Helper function to check if DOB is partially filled
    const isPartialDOB = (dob) => {
        if (!dob) return false; // Empty is allowed
        const defaultPlaceholder = 'mm/dd/yyyy';
        if (dob === defaultPlaceholder) return false; // Treat as empty

        // Regular expression to match complete DOB
        const regexComplete = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
        if (regexComplete.test(dob)) return false; // Complete DOB

        return true; // Partial DOB
    };

    // Helper function to validate complete DOB
    const isValidDate = (dateString) => {
        // Check if the date string matches the MM/DD/YYYY format
        const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
        if (!regex.test(dateString)) return false;

        const [month, day, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        return (
            date.getFullYear() === year &&
            date.getMonth() === month - 1 &&
            date.getDate() === day
        );
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;

        // Set fields to empty string when empty
        if (name === 'DateOfBirth' && value.trim() === '') {
            newValue = '';
        }

        if (name.startsWith('Insurance.')) {
            const insuranceField = name.split('.')[1];
            setPatientData(prevData => ({
                ...prevData,
                Insurance: {
                    ...prevData.Insurance,
                    [insuranceField]: newValue
                }
            }));
        } else {
            setPatientData(prevData => ({
                ...prevData,
                [name]: newValue
            }));
        }

        // Clear the error for the field being edited
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const calculateAge = (dob) => {
        if (!dob) return '';
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const validateForm = () => {
        const newErrors = {};

        // Required fields
        if (!patientData.FirstName?.trim()) newErrors.FirstName = 'First Name is required.';
        if (!patientData.LastName?.trim()) newErrors.LastName = 'Last Name is required.';

        // Email validation
        if (patientData.EmailAddress && !isValidEmail(patientData.EmailAddress)) {
            newErrors.EmailAddress = 'Please enter a valid email address.';
        }

        // DateOfBirth validation
        if (patientData.DateOfBirth && !isValidDate(patientData.DateOfBirth)) {
        newErrors.DateOfBirth = 'Date of Birth must be a valid date.';
}

        return newErrors;
    };

    const formatDateForApi = (dateString) => {
        if (!dateString) return null;
        const [month, day, year] = dateString.split('/');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Perform form validation
        const validationErrors = validateForm();
        setErrors(validationErrors);

        // If no validation errors, proceed with form submission
        if (Object.keys(validationErrors).length === 0) {
            try {
                // Create a copy of patientData to avoid mutating state directly
                const patientDataToSend = { ...patientData };

                patientDataToSend.DateOfBirth = formatDateForApi(patientDataToSend.DateOfBirth);

                const response = await fetchWithAuth('/api/patient', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(patientDataToSend),
                });

                if (response && response.PatientId) {
                    if (onSave && typeof onSave === 'function') {
                        onSave(response);
                    }
                    showMessage('success', 'Patient added successfully!', 3000);
                    onClose();
                } else {
                    throw new Error('Invalid response from server');
                }
            } catch (error) {
                // Avoid showing raw server errors to the user
                showMessage('error', 'Error adding patient. Please check your input and try again.', 0);
                console.error('Error adding patient:', error);
            }
        } else {
            // Aggregate error messages for display
            const errorMessages = Object.values(validationErrors).join(' ');
            showMessage('error', errorMessages || 'Please correct the errors in the form.', 0);
        }
    };



    const handleTabSwitch = (tab) => {
        if (patientData.DateOfBirth && !isValidDate(patientData.DateOfBirth)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                DateOfBirth: 'Date of Birth must be a complete and valid date in MM/DD/YYYY format.'
            }));
            showMessage('error', 'Date of Birth must be a complete and valid date in MM/DD/YYYY format.', 0);
            return; // Prevent tab switch
        }

        // If DOB is filled but invalid, prevent tab switch
        if (patientData.DateOfBirth && !isValidDate(patientData.DateOfBirth)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                DateOfBirth: 'Date of Birth must be a complete and valid date in MM/DD/YYYY format.'
            }));
            showMessage('error', 'Date of Birth must be a complete and valid date in MM/DD/YYYY format.', 0);
            return; // Prevent tab switch
        }

        // Clear DOB error if valid or empty
        setErrors(prevErrors => ({
            ...prevErrors,
            DateOfBirth: ''
        }));

        setActiveTab(tab);
    };


    const renderDemographics = () => (
        <div className="demographics-info">
            <div className="form-row">
                <label>
                    <span className="required-field">First Name:</span>
                    <input
                        type="text"
                        name="FirstName"
                        value={patientData.FirstName}
                        onChange={handleInputChange}
                        maxLength={50}
                        className={errors.FirstName ? 'error' : ''}
                    />
                    {errors.FirstName && <span className="error-message">{errors.FirstName}</span>}
                </label>
                <label>
                    Middle Name:
                    <input
                        type="text"
                        name="MiddleName"
                        value={patientData.MiddleName}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    <span className="required-field">Last Name:</span>
                    <input
                        type="text"
                        name="LastName"
                        value={patientData.LastName}
                        onChange={handleInputChange}
                        maxLength={50}
                        className={errors.LastName ? 'error' : ''}
                    />
                    {errors.LastName && <span className="error-message">{errors.LastName}</span>}
                </label>
            </div>
            <div className="form-row">
                <label>
                    Preferred Name:
                    <input
                        type="text"
                        name="PreferredName"
                        value={patientData.PreferredName}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    Date of Birth:
                    <InputMask
                        mask="99/99/9999"
                        placeholder="mm/dd/yyyy"
                        name="DateOfBirth"
                        value={patientData.DateOfBirth}
                        onChange={handleInputChange}
                        className={errors.DateOfBirth ? 'error' : ''}
                    >
                        {(inputProps) => <input type="text" {...inputProps} />}
                    </InputMask>
                    {errors.DateOfBirth && <span className="error-message">{errors.DateOfBirth}</span>}
                </label>
                <label>
                    Age:
                    <input
                        type="text"
                        value={calculateAge(patientData.DateOfBirth)}
                        readOnly
                        disabled
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Sex Assigned at Birth:
                    <select
                        name="SexAssignedAtBirth"
                        value={patientData.SexAssignedAtBirth}
                        onChange={handleInputChange}
                    >
                        <option value="">Select...</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Intersex">Intersex</option>
                    </select>
                </label>
                <label>
                    Gender Identity:
                    <select
                        name="GenderIdentity"
                        value={patientData.GenderIdentity}
                        onChange={handleInputChange}
                    >
                        <option value="">Select...</option>
                        <option value="Man">Man</option>
                        <option value="Woman">Woman</option>
                        <option value="Non-binary">Non-binary</option>
                        <option value="Other">Other</option>
                    </select>
                </label>
                <label>
                    Preferred Pronouns:
                    <select
                        name="PreferredPronouns"
                        value={patientData.PreferredPronouns}
                        onChange={handleInputChange}
                    >
                        <option value="">Select...</option>
                        <option value="He/Him">He/Him</option>
                        <option value="She/Her">She/Her</option>
                        <option value="They/Them">They/Them</option>
                        <option value="Other">Other</option>
                    </select>
                </label>
            </div>
        </div>
    );

    const renderContactInfo = () => (
        <div className="contact-info">
            <div className="form-row">
                <label>
                    Phone Number:
                    <input
                        type="tel"
                        name="PhoneNumber"
                        value={patientData.PhoneNumber}
                        onChange={handleInputChange}
                        maxLength={20}
                    />
                </label>
                <label>
                    Email Address:
                    <input
                        type="email"
                        name="EmailAddress"
                        value={patientData.EmailAddress}
                        onChange={handleInputChange}
                        maxLength={100}
                        className={errors.EmailAddress ? 'error' : ''}
                    />
                    {errors.EmailAddress && <span className="error-message">{errors.EmailAddress}</span>}
                </label>
            </div>
            <div className="form-row">
                <label>
                    Street:
                    <input
                        type="text"
                        name="Street"
                        value={patientData.Street}
                        onChange={handleInputChange}
                        maxLength={100}
                    />
                </label>
                <label>
                    City:
                    <input
                        type="text"
                        name="City"
                        value={patientData.City}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    State:
                    <select
                        name="State"
                        value={patientData.State}
                        onChange={handleInputChange}
                    >
                        <option value="">Select...</option>
                        {/* Add all US states here */}
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        {/* ... other states ... */}
                        <option value="WY">Wyoming</option>
                    </select>
                </label>
                <label>
                    Zip Code:
                    <input
                        type="text"
                        name="ZipCode"
                        value={patientData.ZipCode}
                        onChange={handleInputChange}
                        maxLength={20}
                    />
                </label>
                <label>
                    Country:
                    <select
                        name="Country"
                        value={patientData.Country}
                        onChange={handleInputChange}
                    >
                        <option value="">Select...</option>
                        <option value="USA">United States</option>
                        {/* Add other countries as needed */}
                    </select>
                </label>
            </div>
            <h4>Emergency Contact</h4>
            <div className="form-row">
                <label>
                    Name:
                    <input
                        type="text"
                        name="EmergencyContactName"
                        value={patientData.EmergencyContactName}
                        onChange={handleInputChange}
                        maxLength={100}
                    />
                </label>
                <label>
                    Relationship:
                    <input
                        type="text"
                        name="EmergencyContactRelationship"
                        value={patientData.EmergencyContactRelationship}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    Phone Number:
                    <input
                        type="tel"
                        name="EmergencyContactPhone"
                        value={patientData.EmergencyContactPhone}
                        onChange={handleInputChange}
                        maxLength={20}
                    />
                </label>
            </div>
            <h4>Legally Authorized Representative</h4>
            <div className="form-row">
                <label>
                    Name:
                    <input
                        type="text"
                        name="LegalAuthRepName"
                        value={patientData.LegalAuthRepName}
                        onChange={handleInputChange}
                        maxLength={100}
                    />
                </label>
                <label>
                    Relationship:
                    <input
                        type="text"
                        name="LegalAuthRepRelationship"
                        value={patientData.LegalAuthRepRelationship}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    Phone Number:
                    <input
                        type="tel"
                        name="LegalAuthRepPhone"
                        value={patientData.LegalAuthRepPhone}
                        onChange={handleInputChange}
                        maxLength={20}
                    />
                </label>
            </div>
        </div>
    );

    const renderInsurance = () => (
        <div className="insurance-info">
            <div className="form-row">
                <label>
                    Carrier:
                    <input
                        type="text"
                        name="Insurance.Carrier"
                        value={patientData.Insurance.Carrier}
                        onChange={handleInputChange}
                        maxLength={100}
                    />
                </label>
                <label>
                    Subscriber:
                    <input
                        type="text"
                        name="Insurance.Subscriber"
                        value={patientData.Insurance.Subscriber}
                        onChange={handleInputChange}
                        maxLength={100}
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Coverage:
                    <input
                        type="text"
                        name="Insurance.Coverage"
                        value={patientData.Insurance.Coverage}
                        onChange={handleInputChange}
                        maxLength={100}
                    />
                </label>
                <label>
                    Subscriber ID:
                    <input
                        type="text"
                        name="Insurance.SubscriberId"
                        value={patientData.Insurance.SubscriberId}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Group:
                    <input
                        type="text"
                        name="Insurance.Group"
                        value={patientData.Insurance.Group}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    RxBIN:
                    <input
                        type="text"
                        name="Insurance.RxBIN"
                        value={patientData.Insurance.RxBIN}
                        onChange={handleInputChange}
                        maxLength={20}
                    />
                </label>
            </div>
        </div>
    );

    return (
        <div className="add-patient-form">
            <div className="form-header">
                <h2>Add New Patient</h2>
                <img src={backIcon} alt="Close" className="back-icon" onClick={onClose} />
            </div>
            <div className="tabs">
                <button
                    className={activeTab === 'demographics' ? 'active' : ''}
                    onClick={() => handleTabSwitch('demographics')}
                >
                    Demographics
                </button>
                <button
                    className={activeTab === 'contactInfo' ? 'active' : ''}
                    onClick={() => handleTabSwitch('contactInfo')}
                >
                    Contact Info
                </button>
                <button
                    className={activeTab === 'insurance' ? 'active' : ''}
                    onClick={() => handleTabSwitch('insurance')}
                >
                    Insurance
                </button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="tab-content">
                    {activeTab === 'demographics' && renderDemographics()}
                    {activeTab === 'contactInfo' && renderContactInfo()}
                    {activeTab === 'insurance' && renderInsurance()}
                </div>
                <div className="form-buttons">
                    <button type="submit">Add Patient</button>
                </div>
            </form>
        </div>
    );
};

export default AddPatient;
