import React from 'react';

const Scheduler = () => {
    return (
        <div>
            <h1>Scheduler</h1>
            {/* Add your Investigational Product page content here */}
        </div>
    );
};

export default Scheduler;
