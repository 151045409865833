// Cerebro/ClientApp/src/pages/Login/LoginPage.js

import React, { useState, useRef, useEffect } from 'react';
import { useMessage } from '../../components/Message/MessageProvider';
import authService from '../../components/api-authorization/AuthorizeService';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import './LoginPage.css';
import loginBackground from '../../assets/goat.jpg';
import signupBackground from '../../assets/goat.jpg';
import backIcon from '../../assets/icons/Back.png';

const LoginPage = () => {
    const [mode, setMode] = useState('login');
    const { showMessage, hideMessage } = useMessage();
    const navigate = useNavigate();
    const recaptchaRef = useRef();
    const [passwordConfig, setPasswordConfig] = useState(null);
    const [forgotPasswordMode, setForgotPasswordMode] = useState(false);

    const toggleMode = () => {
        setMode(prevMode => prevMode === 'login' ? 'signup' : 'login');
    };

    const fetchPasswordConfig = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/auth/passwordconfig`);
        if (response.ok) {
            return await response.json();
        }
        throw new Error('Failed to fetch password configuration');
    };

    useEffect(() => {
        fetchPasswordConfig().then(setPasswordConfig).catch(console.error);
    }, []);

    const handleError = (errorData) => {
        if (errorData.errors && Array.isArray(errorData.errors)) {
            const errorMessages = errorData.errors.map(error => {
                if (error.includes('Password') && error.includes('at least')) {
                    return `Password must be at least ${passwordConfig ? passwordConfig.requiredLength : 'the required number of'} characters long.`;
                }
                if (error.includes('Username') && error.includes('already taken')) {
                    return 'Username already exists.';
                }
                if (error.includes('Email') && error.includes('already taken')) {
                    return 'Email already exists.';
                }
                return error;
            });
            showMessage('error', errorMessages.join(' '), -100, 0);
        } else if (errorData.message) {
            if (errorData.message.includes('duplicate key row') && errorData.message.includes('IX_AspNetUsers_Email')) {
                showMessage('error', 'Email already exists.', -100, 0);
            } else if (errorData.message.includes('duplicate key row') && errorData.message.includes('IX_AspNetUsers_UserName')) {
                showMessage('error', 'Username already exists.', -100, 0);
            } else {
                showMessage('error', errorData.message, -100, 0);
            }
        } else {
            showMessage('error', 'An unexpected error occurred. Please try again later.', -100, 0);
        }
        // Reset reCAPTCHA when an error occurs
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    };

    const backButtonStyle = {
        position: 'absolute',
        top: '1rem',
        left: '2rem',
        background: 'transparent',
        border: 'none',
        outline: 'none',     // Remove the outline
        cursor: 'pointer',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '1.5rem',
        height: '1.5rem',
        zIndex: 10,
        // Note: Inline styles cannot handle ':hover' pseudo-class
    };

    const backButtonImageStyle = {
        width: '100%',
        height: '100%',
    };


    const BackButton = ({ onClick }) => (
        <button
            className="back-button-advanced"
            style={backButtonStyle}
            onClick={onClick}
            aria-label="Back to Login"
        >
            <img src={backIcon} alt="" style={backButtonImageStyle} />
        </button>
    );


    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        const url = mode === 'login' ? `${apiUrl}/api/auth/login` : `${apiUrl}/api/auth/register`;
        const formElements = e.target.elements;
        let userData;

        if (mode === 'login') {
            const username = formElements.username.value;
            const password = formElements.password.value;
            userData = { username, password };
        } else {
            const username = formElements.username.value;
            const password = formElements.createpassword.value;
            const repeatPassword = formElements.repeatpassword.value;
            const email = formElements.email.value;

            if (password !== repeatPassword) {
                showMessage('error', 'Passwords do not match.', -100, 0);
                return;
            }

            if (passwordConfig && password.length < passwordConfig.requiredLength) {
                showMessage('error', `Password must be at least ${passwordConfig.requiredLength} characters long.`, -100, 0);
                return;
            }

            const recaptchaValue = recaptchaRef.current.getValue();
            if (!recaptchaValue) {
                showMessage('error', 'Please complete the CAPTCHA', -100, 0);
                return;
            }

            userData = {
                username: username,
                email: email,
                password: password,
                fullName: formElements.fullname.value,
                recaptchaResponse: recaptchaValue
            };
        }

        hideMessage();

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            const contentType = response.headers.get("content-type");
            let responseData;
            if (contentType && contentType.includes("application/json")) {
                responseData = await response.json();
            } else {
                responseData = { message: await response.text() };
            }

            if (response.ok) {
                if (mode === 'login') {
                    const result = await authService.signIn(userData);
                    if (result.status === 'success') {
                        const userRoles = await authService.fetchUserRoles();
                        handleRedirect(userRoles, result.timestamp);
                    } else {
                        showMessage('error', 'Invalid username or password.', -100, 0);
                    }
                } else {
                    showMessage('success', 'User registered successfully!', 0, 0, () => {
                        setMode('login');
                    });
                }
            } else {
                if (mode === 'login') {
                    showMessage('error', 'Invalid username or password.', -100, 0);
                } else {
                    handleError(responseData);
                }
            }
        } catch (error) {
            showMessage('error', 'An unexpected error occurred. Please try again later.', -100, 0);
        }
    };

    // Function to parse the returnUrl from the query string
    const getReturnUrl = (queryString) => {
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('returnUrl') || '/dashboard';
    };

    const handleRedirect = (userRoles, timestamp) => {
        if (!userRoles) {
            showMessage('error', 'Failed to fetch user roles. Please try logging in again.', -100, 0);
            return;
        }

        // Clear the forward history
        window.history.forward();

        // Navigate to dashboard, replacing the current history entry
        navigate('/dashboard', {
            state: { userRoles, loginTimestamp: timestamp },
            replace: true
        });
    };


    const handleForgotPassword = (e) => {
        e.preventDefault();
        setForgotPasswordMode(true);
    };

    const handleForgotPasswordSubmit = async (e) => {
        e.preventDefault();
        const email = e.target.elements.email.value;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/forgotpassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();

            if (response.ok) {
                showMessage('success', data.message, 0, 0);
                setForgotPasswordMode(false);
            } else {
                showMessage('error', 'An error occurred. Please try again later.', -100, 0);
            }
        } catch (error) {
            showMessage('error', 'An unexpected error occurred. Please try again later.', -100, 0);
        }
    };

    const LoginForm = ({ mode, onSubmit, recaptchaRef, passwordConfig, onForgotPassword }) => {
        return (
            <form onSubmit={onSubmit}>
                <div className="form-block__input-wrapper">
                    {mode === 'login' && (
                        <div className="form-group form-group--login">
                            <Input type="text" id="username" name="username" label="user name" />
                            <Input type="password" id="password" name="password" label="password" />
                        </div>
                    )}
                    {mode === 'signup' && (
                        <div className="form-group form-group--signup">
                            <Input type="text" id="fullname" name="fullname" label="full name" />
                            <Input type="text" id="username" name="username" label="username" />
                            <Input type="email" id="email" name="email" label="email" />
                            <Input
                                type="password"
                                id="createpassword"
                                name="createpassword"
                                label={`password (min ${passwordConfig ? passwordConfig.requiredLength : 'required'} characters)`}
                            />
                            <Input type="password" id="repeatpassword" name="repeatpassword" label="repeat password" />
                            <div className="recaptcha-container">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <button
                    className="button button--primary full-width"
                    type="submit"
                    style={{ backgroundColor: '#014278', color: '#fff' }} // Default button color
                    onMouseOver={(e) => e.target.style.backgroundColor = '#0268A6'} 
                    onMouseOut={(e) => e.target.style.backgroundColor = '#014278'} // Back to default
                >
                    {mode === 'login' ? 'Log In' : 'Sign Up'}
                </button>
                {mode === 'login' && (
                    <div className="forgot-password-container">
                        <a href="#" className="forgot-password-link" onClick={onForgotPassword}>
                            Forgot Password?
                        </a>
                    </div>
                )}
            </form>
        );
    };

    const Input = ({ id, name, type, label }) => (
        <input className="form-group__input" type={type} id={id} name={name} placeholder={label} required />
    );

    const ForgotPasswordForm = ({ onSubmit }) => {
        return (
            <form onSubmit={onSubmit}>
                <div className="form-block__input-wrapper">
                    <div className="form-group form-group--forgot-password">
                        <Input type="email" id="email" name="email" label="email" />
                    </div>
                </div>
                <button className="button button--primary full-width" type="submit">Reset Password</button>
            </form>
        );
    };


    return (
        <div>
            <div
                className={`form-block-wrapper form-block-wrapper--is-${mode}`}
                style={mode === 'login' ? {
                    backgroundImage: `url(${loginBackground})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                } : {
                    backgroundImage: `url(${signupBackground})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
            ></div>
            <section className={`form-block form-block--is-${mode}`} style={{ position: 'relative' }}>
                {forgotPasswordMode && (
                    <BackButton onClick={() => setForgotPasswordMode(false)} />
                )}
                <header className="form-block__header">
                    <div className="form-block__toggle-block">
                        {!forgotPasswordMode && (
                            <>
                                <span>{mode === 'login' ? "Don't" : 'Already'} have an account? Click here &#8594;</span>
                                <input
                                    id="form-toggler"
                                    type="checkbox"
                                    checked={mode === 'signup'}
                                    onChange={toggleMode}
                                />
                                <label htmlFor="form-toggler"></label>
                            </>
                        )}
                    </div>
                    <h1 className={forgotPasswordMode ? 'centered-header' : ''}>
                        {forgotPasswordMode ? 'Forgot Password' : mode === 'login' ? 'Welcome back!' : 'Sign up'}
                    </h1>
                </header>
                {forgotPasswordMode ? (
                    <ForgotPasswordForm onSubmit={handleForgotPasswordSubmit} />
                ) : (
                    <LoginForm
                        mode={mode}
                        onSubmit={handleSubmit}
                        recaptchaRef={recaptchaRef}
                        passwordConfig={passwordConfig}
                        onForgotPassword={() => setForgotPasswordMode(true)}
                    />
                )}
            </section>
        </div>
    );
};

export default LoginPage;