import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LoginPage from './pages/Login/LoginPage';
import Dashboard from './pages/Dashboard/Dashboard';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import authService from './components/api-authorization/AuthorizeService';
import ResetPassword from './pages/Login/ResetPassword';

function App() {
    const navigate = useNavigate();

    useEffect(() => {
        const checkUser = async () => {
            const user = await authService.getUser();
            if (user) {
                // If user is authenticated, you might want to redirect to dashboard
                // navigate('/dashboard');
            } else {
                // If user is not authenticated, you might want to redirect to login
                // navigate('/login');
            }
        };
        checkUser();
    }, [navigate]);

    return (
        <div className="App">
            <div className="content">
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/reset-password/:userId/:token/:email" element={<ResetPassword />} />
                    <Route path="/dashboard" element={<AuthorizeRoute element={<Dashboard />} />} />
                    {ApiAuthorizationRoutes.map((route, index) => (
                        <Route key={index} path={route.path} element={route.element} />
                    ))}
                    {/* Add a catch-all route that redirects to LoginPage */}
                    <Route path="*" element={<LoginPage />} />
                </Routes>
            </div>
        </div>
    );
}

export default App;
