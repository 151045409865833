// Cerebro/ClientApp/src/pages/Dashboard/EHR/Dialog/EditPatient.js

import React, { useState, useEffect } from 'react';
import './AddPatient.css';
import backIcon from '../../../../assets/icons/Close.png';
import editIcon from '../../../../assets/icons/Edit.png';
import deleteIcon from '../../../../assets/icons/Delete.png';
import useFetchWithAuth from '../../../../utils/useFetchWithAuth';
import { useMessage } from '../../../../components/Message/MessageProvider';
import InputMask from 'react-input-mask';

const EditPatient = ({ patient, onClose, onPatientUpdated, onPatientDeleted }) => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [activeTab, setActiveTab] = useState('demographics');
    const [errors, setErrors] = useState({});
    const [patientData, setPatientData] = useState({
        ...patient,
        Insurance: patient.Insurance || {}
    });
    const [isEditing, setIsEditing] = useState(false);
    const [originalInsuranceData, setOriginalInsuranceData] = useState(patient.Insurance || {});
    useEffect(() => {
        setPatientData({
            ...patient,
            Insurance: patient.Insurance || {}
        });
        setOriginalInsuranceData(patient.Insurance || {});
    }, [patient]);


    useEffect(() => {
        setPatientData({
            ...patient,
            Insurance: patient.Insurance || {}
        });
    }, [patient]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;

        if (name === 'DateOfBirth' && value.trim() === '') {
            newValue = '';
        }

        if (name.startsWith('Insurance.')) {
            const insuranceField = name.split('.')[1];
            setPatientData(prevData => ({
                ...prevData,
                Insurance: {
                    ...prevData.Insurance,
                    [insuranceField]: newValue
                }
            }));
        } else {
            setPatientData(prevData => ({
                ...prevData,
                [name]: newValue
            }));
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const calculateAge = (dob) => {
        if (!dob) return '';
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const isInsuranceDataChanged = () => {
        const fields = ['Carrier', 'Subscriber', 'Coverage', 'SubscriberId', 'Group', 'RxBIN'];
        return fields.some(field => {
            const originalValue = originalInsuranceData[field] || '';
            const currentValue = patientData.Insurance[field] || '';
            return originalValue !== currentValue;
        });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!patientData.FirstName?.trim()) newErrors.FirstName = 'First Name is required.';
        if (!patientData.LastName?.trim()) newErrors.LastName = 'Last Name is required.';

        if (patientData.EmailAddress && !isValidEmail(patientData.EmailAddress)) {
            newErrors.EmailAddress = 'Please enter a valid email address.';
        }

        if (patientData.DateOfBirth && !isValidDate(patientData.DateOfBirth)) {
            newErrors.DateOfBirth = 'Date of Birth must be a valid date.';
        }

        return newErrors;
    };

    const formatDateForApi = (dateString) => {
        if (!dateString) return null;
        const [month, day, year] = dateString.split('/');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidDate = (dateString) => {
        const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
        if (!regex.test(dateString)) return false;

        const [month, day, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        return (
            date.getFullYear() === year &&
            date.getMonth() === month - 1 &&
            date.getDate() === day
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            try {
                const patientDataToSend = { ...patientData };
                patientDataToSend.DateOfBirth = formatDateForApi(patientDataToSend.DateOfBirth);

                // Set InsuranceChanged based on whether the insurance data has changed
                patientDataToSend.InsuranceChanged = isInsuranceDataChanged();

                // Log the payload
                console.log('Patient data to send:', patientDataToSend);

                const response = await fetchWithAuth(`/api/patient/${patientData.PatientId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(patientDataToSend),
                });

                if (response && response.PatientId) {
                    showMessage('success', 'Patient updated successfully!', 3000);
                    setIsEditing(false);
                    if (onPatientUpdated) {
                        onPatientUpdated(response);
                    }
                } else {
                    throw new Error('Invalid response from server');
                }
            } catch (error) {
                showMessage('error', 'Error updating patient. Please check your input and try again.', 0);
                console.error('Error updating patient:', error);
            }
        } else {
            const errorMessages = Object.values(validationErrors).join(' ');
            showMessage('error', errorMessages || 'Please correct the errors in the form.', 0);
        }
    };

    const handleDelete = async () => {
        try {
            await fetchWithAuth(`/api/patient/${patientData.PatientId}`, {
                method: 'DELETE',
            });
            showMessage('success', 'Patient deleted successfully!', 3000);
            if (onPatientDeleted) {
                onPatientDeleted(patientData.PatientId);
            }
            onClose();
        } catch (error) {
            showMessage('error', 'Error deleting patient: ' + error.message, 0);
        }
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const renderDemographics = () => (
        <div className="demographics-info">
            <div className="form-row">
                <label>
                    <span className="required-field">First Name:</span>
                    <input
                        type="text"
                        name="FirstName"
                        value={patientData.FirstName}
                        onChange={handleInputChange}
                        maxLength={50}
                        className={errors.FirstName ? 'error' : ''}
                        disabled={!isEditing}
                    />
                    {errors.FirstName && <span className="error-message">{errors.FirstName}</span>}
                </label>
                <label>
                    Middle Name:
                    <input
                        type="text"
                        name="MiddleName"
                        value={patientData.MiddleName}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    <span className="required-field">Last Name:</span>
                    <input
                        type="text"
                        name="LastName"
                        value={patientData.LastName}
                        onChange={handleInputChange}
                        maxLength={50}
                        className={errors.LastName ? 'error' : ''}
                        disabled={!isEditing}
                    />
                    {errors.LastName && <span className="error-message">{errors.LastName}</span>}
                </label>
            </div>
            <div className="form-row">
                <label>
                    Preferred Name:
                    <input
                        type="text"
                        name="PreferredName"
                        value={patientData.PreferredName}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Date of Birth:
                    <InputMask
                        mask="99/99/9999"
                        placeholder="mm/dd/yyyy"
                        name="DateOfBirth"
                        value={patientData.DateOfBirth}
                        onChange={handleInputChange}
                        className={errors.DateOfBirth ? 'error' : ''}
                        disabled={!isEditing}
                    />
                    {errors.DateOfBirth && <span className="error-message">{errors.DateOfBirth}</span>}
                </label>
                <label>
                    Age:
                    <input
                        type="text"
                        value={calculateAge(patientData.DateOfBirth)}
                        readOnly
                        disabled
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Sex Assigned at Birth:
                    <select
                        name="SexAssignedAtBirth"
                        value={patientData.SexAssignedAtBirth}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="">Select...</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Intersex">Intersex</option>
                    </select>
                </label>
                <label>
                    Gender Identity:
                    <select
                        name="GenderIdentity"
                        value={patientData.GenderIdentity}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="">Select...</option>
                        <option value="Man">Man</option>
                        <option value="Woman">Woman</option>
                        <option value="Non-binary">Non-binary</option>
                        <option value="Other">Other</option>
                    </select>
                </label>
                <label>
                    Preferred Pronouns:
                    <select
                        name="PreferredPronouns"
                        value={patientData.PreferredPronouns}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="">Select...</option>
                        <option value="He/Him">He/Him</option>
                        <option value="She/Her">She/Her</option>
                        <option value="They/Them">They/Them</option>
                        <option value="Other">Other</option>
                    </select>
                </label>
            </div>
        </div>
    );

    const renderContactInfo = () => (
        <div className="contact-info">
            <div className="form-row">
                <label>
                    Phone Number:
                    <input
                        type="tel"
                        name="PhoneNumber"
                        value={patientData.PhoneNumber}
                        onChange={handleInputChange}
                        maxLength={20}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Email Address:
                    <input
                        type="email"
                        name="EmailAddress"
                        value={patientData.EmailAddress}
                        onChange={handleInputChange}
                        maxLength={100}
                        className={errors.EmailAddress ? 'error' : ''}
                        disabled={!isEditing}
                    />
                    {errors.EmailAddress && <span className="error-message">{errors.EmailAddress}</span>}
                </label>
            </div>
            <div className="form-row">
                <label>
                    Street:
                    <input
                        type="text"
                        name="Street"
                        value={patientData.Street}
                        onChange={handleInputChange}
                        maxLength={100}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    City:
                    <input
                        type="text"
                        name="City"
                        value={patientData.City}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    State:
                    <select
                        name="State"
                        value={patientData.State}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="">Select...</option>
                        {/* Add all US states here */}
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        {/* ... other states ... */}
                        <option value="WY">Wyoming</option>
                    </select>
                </label>
                <label>
                    Zip Code:
                    <input
                        type="text"
                        name="ZipCode"
                        value={patientData.ZipCode}
                        onChange={handleInputChange}
                        maxLength={20}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Country:
                    <select
                        name="Country"
                        value={patientData.Country}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="">Select...</option>
                        <option value="USA">United States</option>
                        {/* Add other countries as needed */}
                    </select>
                </label>
            </div>
            <h4>Emergency Contact</h4>
            <div className="form-row">
                <label>
                    Name:
                    <input
                        type="text"
                        name="EmergencyContactName"
                        value={patientData.EmergencyContactName}
                        onChange={handleInputChange}
                        maxLength={100}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Relationship:
                    <input
                        type="text"
                        name="EmergencyContactRelationship"
                        value={patientData.EmergencyContactRelationship}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Phone Number:
                    <input
                        type="tel"
                        name="EmergencyContactPhone"
                        value={patientData.EmergencyContactPhone}
                        onChange={handleInputChange}
                        maxLength={20}
                        disabled={!isEditing}
                    />
                </label>
            </div>
            <h4>Legally Authorized Representative</h4>
            <div className="form-row">
                <label>
                    Name:
                    <input
                        type="text"
                        name="LegalAuthRepName"
                        value={patientData.LegalAuthRepName}
                        onChange={handleInputChange}
                        maxLength={100}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Relationship:
                    <input
                        type="text"
                        name="LegalAuthRepRelationship"
                        value={patientData.LegalAuthRepRelationship}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Phone Number:
                    <input
                        type="tel"
                        name="LegalAuthRepPhone"
                        value={patientData.LegalAuthRepPhone}
                        onChange={handleInputChange}
                        maxLength={20}
                        disabled={!isEditing}
                    />
                </label>
            </div>
        </div>
    );

    const renderInsurance = () => (
        <div className="insurance-info">
            <div className="form-row">
                <label>
                    Carrier:
                    <input
                        type="text"
                        name="Insurance.Carrier"
                        value={patientData.Insurance?.Carrier || ''}
                        onChange={handleInputChange}
                        maxLength={100}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Subscriber:
                    <input
                        type="text"
                        name="Insurance.Subscriber"
                        value={patientData.Insurance?.Subscriber || ''}
                        onChange={handleInputChange}
                        maxLength={100}
                        disabled={!isEditing}
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Coverage:
                    <input
                        type="text"
                        name="Insurance.Coverage"
                        value={patientData.Insurance?.Coverage || ''}
                        onChange={handleInputChange}
                        maxLength={100}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Subscriber ID:
                    <input
                        type="text"
                        name="Insurance.SubscriberId"
                        value={patientData.Insurance?.SubscriberId || ''}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Group:
                    <input
                        type="text"
                        name="Insurance.Group"
                        value={patientData.Insurance?.Group || ''}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    RxBIN:
                    <input
                        type="text"
                        name="Insurance.RxBIN"
                        value={patientData.Insurance?.RxBIN || ''}
                        onChange={handleInputChange}
                        maxLength={20}
                        disabled={!isEditing}
                    />
                </label>
            </div>
        </div>
    );

    return (
        <div className="add-patient-form">
            <div className="form-header">
                <div className="edit-actions">
                    {isEditing ? (
                        <img src={backIcon} alt="Close" onClick={handleEditToggle} />
                    ) : (
                        <img src={editIcon} alt="Edit" onClick={handleEditToggle} />
                    )}
                    <img src={deleteIcon} alt="Delete" onClick={handleDelete} />
                </div>
                <h2>Edit Patient</h2>
                <img src={backIcon} alt="Close" className="back-icon" onClick={onClose} />
            </div>
            <div className="tabs">
                <button
                    className={activeTab === 'demographics' ? 'active' : ''}
                    onClick={() => setActiveTab('demographics')}
                >
                    Demographics
                </button>
                <button
                    className={activeTab === 'contactInfo' ? 'active' : ''}
                    onClick={() => setActiveTab('contactInfo')}
                >
                    Contact Info
                </button>
                <button
                    className={activeTab === 'insurance' ? 'active' : ''}
                    onClick={() => setActiveTab('insurance')}
                >
                    Insurance
                </button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="tab-content">
                    {activeTab === 'demographics' && renderDemographics()}
                    {activeTab === 'contactInfo' && renderContactInfo()}
                    {activeTab === 'insurance' && renderInsurance()}
                </div>
                {isEditing && (
                    <div className="form-buttons">
                        <button type="submit">Save Changes</button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default EditPatient;